<template>
  <div class="experList">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="experlist-main">
      <el-tabs v-model="activeExperTab" @tab-click="tabClick()">
        <el-tab-pane v-for="item in experTypeList" :key="item.id" :label="item.name"
          :name="item.id">

          <template v-if="activeExperTab === '0'">
            <Input class="search-input" v-model="searchForm.keyword" :placeholder="$t('common_search_keyword')" clearable
              search :enter-button="true" @on-enter="getTableData()" @on-search="getTableData()" />
            <el-table :data="tableData" border size="small" v-loading="tableLoading" >
              <el-table-column prop="data.id" label="ID" width="100"></el-table-column>
              <el-table-column prop="data.title" label="标题"></el-table-column>
              <el-table-column prop="id" label="难度" width="100">
                <template v-slot="{ row }">
                  <div>{{ levelObj[row.data.level] }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="id" label="语言" width="120" show-overflow-tooltip>
                <template v-slot="{ row }">
                  <div>{{ row.experiment.code_run_cmd }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="id" label="知识点" show-overflow-tooltip>
                <template v-slot="{ row }">
                  {{
                    row?.data?.labelKeywords?.reduce((pre, cur) => {
                      pre.push(cur.label.name);
                      return pre;
                    }, []).join(",")
                  }}
                </template>
              </el-table-column>
              <el-table-column prop="id" label="创建时间">
                <template v-slot="{ row }">
                  <div>{{ util.timeFormatter(new Date(+row.data.created_at * 1000), 'yyyy-MM-dd') }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template v-slot="{ row }">
                  <el-button type="primary" size="small" @click="doingExercises(row)">去做题</el-button>
                </template>
              </el-table-column>
            </el-table>
            <Pagin class="pagin" :pagination="pagination" @currentChange="currentChange" @sizeChange="sizeChange" />
          </template>
          <template v-if="activeExperTab === '1'">
<!--            <Input class="search-input" v-model="searchForm.keyword" :placeholder="$t('common_search_keyword')" clearable-->
<!--                   search :enter-button="true" @on-enter="getTableData()" @on-search="getTableData()" />-->
            <el-table :data="virtualTableData" border size="small" v-loading="virtualLoading" >
              <el-table-column prop="id" label="ID" width="100"></el-table-column>
              <el-table-column prop="name" label="标题"></el-table-column>
              <el-table-column label="创建时间">
                <template v-slot="{ row }">
                  <div>{{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd') }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template v-slot="{ row }">
                  <el-button type="primary" size="small" @click="doingVirtualExercises(row)">去实验</el-button>
                </template>
              </el-table-column>
            </el-table>
            <Pagin class="pagin" :pagination="virtualPagination" @currentChange="virtualCurrentChange" @sizeChange="virtualSizeChange" />
          </template>

          <template v-if="activeExperTab === '2'">
            <el-table :data="tsTableList" border size="small" v-loading="tsLoading" >
              <el-table-column prop="id" label="ID" width="100"></el-table-column>
              <el-table-column prop="name" label="名称"></el-table-column>
              <el-table-column prop="name" label="创建时间">
                <template v-slot='{ row }'>
                  {{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd') }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template v-slot='{ row }'>
                  <el-button size="small" type="primary" @click="goExper(row)">去实验</el-button>
                </template>
              </el-table-column>
            </el-table>
            <Pagin class="pagin" :pagination="tsPagination" @currentChange="tsCurrentChange" @sizeChange="tsSizeChange" />
          </template>

          <template v-if="activeExperTab === '3'">
            <div class="process-top">
              <Input class="search-input" v-model="processSearchForm.keyword" :placeholder="$t('common_search_keyword')"
                clearable search :enter-button="true" @on-enter="getProcessTableData()"
                @on-search="getProcessTableData()" />
<!--              <el-button size="small" type="primary" @click="createProcess()">创建流程图</el-button>-->
            </div>
            <el-table :data="processList" border size="small" v-loading="processTableLoading"
              :height="util.getTableHeight(52)">
              <el-table-column prop="id" label="ID" width="100"></el-table-column>
              <el-table-column prop="name" label="标题"></el-table-column>
              <el-table-column prop="id" label="创建时间">
                <template v-slot="{ row }">
                  <div>{{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd') }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="id" label="更新时间">
                <template v-slot="{ row }">
                  <div>{{ util.timeFormatter(new Date(+row.updated_at * 1000), 'yyyy-MM-dd') }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150">
                <template v-slot="{ row }">
<!--                  <el-button type="primary" size="small" @click="editProcess(row)">编辑</el-button>-->
<!--                  <el-button type="danger" size="small" @click="deleteProcess(row)">删除</el-button>-->
                  <el-button type="primary" size="small" @click="goExperiment(row)">去实验</el-button>
                </template>
              </el-table-column>
            </el-table>
            <Pagin class="pagin" :pagination="processPagination" @currentChange="processCurrentChange"
              @sizeChange="processSizeChange" />
          </template>
          <template v-if="activeExperTab === '4'">
            <div style="display: flex; align-items: center; margin-bottom: 20px;">
              <div>
                <el-alert :title="alertText" type="warning" />
                <el-alert :title="alertText1" type="warning" />
              </div>
              <el-button type="primary" size="small" style="margin-left: 10px;" @click="setAccessKey()">设置Access Key</el-button>
            </div>
            <el-table :data="aliTableData" border size="small" v-loading="aliLoading" >
              <el-table-column prop="id" label="ID" width="100"></el-table-column>
              <el-table-column prop="name" label="标题"></el-table-column>
              <el-table-column label="创建时间">
                <template v-slot="{ row }">
                  <div>{{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd') }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template v-slot="{ row }">
                  <el-button type="primary" size="small" @click="doingaliExercises(row)">去实验</el-button>
                </template>
              </el-table-column>
            </el-table>
            <Pagin class="pagin" :pagination="aliPagination" @currentChange="virtualCurrentChange" @sizeChange="virtualSizeChange" />
          </template>

        </el-tab-pane>
      </el-tabs>
    </div>
    <el-drawer :size="800" :visible.sync="aliModal" @close="resetForm('ruleForm')">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">添加阿里云账号</div>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="240px" class="demo-ruleForm">
        <el-form-item label="请输入阿里云access_key" prop="aliAk">
          <el-input v-model="ruleForm.aliAk"></el-input>
        </el-form-item>
        <el-form-item label="请输入阿里云access_secret_key" prop="aliSk">
          <el-input v-model="ruleForm.aliSk"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import util from "@/utils/tools.js";
import Pagin from "@/components/myPagin.vue";
export default {
  components: {
    Pagin
  },
  data() {
    return {
      util,
      // 当前选中的实验的tab
      activeExperTab: "0",
      // 实验类型数据
      experTypeList: [],
      alertText:`一. 操作流程: 1.授权:学生登录阿里云创建AK,SK, 学生在实战云填写AK,SK  2.创建实验:学生在实战云打开实验列表,选择并创建实验  3.操作实验:学生参照实战云上的实验手册, 在阿里云上执行操作. `,
      alertText1:`二. 阿里云AKSK操作指引 https://alidocs.dingtalk.com/i/p/dN0G7R9PrNRrMmWY/docs/MNDoBb60VLmQxnw7TYQ2PZdGJlemrZQ3`,
      // 在线编程实验相关数据
      // 列表数据
      tableData: [],
      // 试题难度数据
      levelObj: {
        1: "简单",
        2: "中等",
        3: "困难"
      },
      tableLoading: false,
      // 列表分页器数据
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      // 列表查询参数
      searchForm: {
        keyword: ""
      },


      // 流程图实验相关数据
      processList: [],
      processSearchForm: {
        keyword: ""
      },
      processPagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      processTableLoading: false,
      processForm: {
        name: "",
        process_data: ""
      },


      // 第三方系统相关数据
      tsTableList: [],
      tsPagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      tsLoading: false,
      virtualTableData:[],
      virtualPagination:{
        page: 1,
        pageSize: 10,
        total: 0
      },
      virtualLoading:false,
      aliTableData:[],
      aliPagination:{
        page: 1,
        pageSize: 10,
        total: 0
      },
      aliLoading:false,
      aliModal:false,
      ruleForm: {
        aliAk: '',
        aliSk: '',
      },
      rules: {
        aliAk: [
          { required: true, message: '请输入阿里云access_key（建议子账号）', trigger: 'blur' },
        ],
        aliSk:[
          { required: true, message: '请输入阿里云access_secret_key（建议子账号）', trigger: 'blur' },
        ]
      },
      curAliData:{},
      enterExper:false
    }
  },
  methods: {
    setAccessKey(){
      const { ruleForm }=this;
      const userInfo=this.$store.state?.user?.userInfo || {}
      const { userAttribute }=userInfo;
      if(userAttribute){
        ruleForm.aliAk=userAttribute.ali_ak;
        ruleForm.aliSk=userAttribute.ali_sk;
      }
      this.aliModal = true;
    },
    tabClick() {
      if (this.activeExperTab === "0") {
        this.getTableData();
      } else if (this.activeExperTab === "3") {
        this.getProcessTableData();
      }
      this.$router.replace({
        path: "/trainingcamp/experiment-code/" + this.activeExperTab
      });
    },

    getTableData() {
      const { pagination, searchForm } = this;
      this.tableLoading = true;
      this.api.dataset.boundTestList({
        ...searchForm,
        page: pagination.page,
        page_size: pagination.pageSize,
      }).then(res => {
        pagination.total = Number(res.count);
        const arr = [];
        for (let key in res.experiment_types) {
          arr.push({
            id: key,
            name: res.experiment_types[key]
          });
        }
        this.experTypeList = arr;
        this.tableData = res.list || [];
        this.tableLoading = false;
      });
    },
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getTableData();
    },
    sizeChange(size) {
      const { pagination } = this;
      pagination.pageSize = size;
      this.getTableData();
    },
    doingExercises(row) {
      this.$router.push({
        path: "/program",
        query: {
          data_id: row.data_id,
          experiment_id: row.experiment_id
        }
      });
    },
    handleKeyword() {
      const { searchForm } = this;
      if (this.$route.query.experiment_id) {
        searchForm.keyword = this.$route.query.experiment_id;
      }
    },


    // 流程图相关函数
    getProcessTableData() {
      const { processPagination, processSearchForm } = this;
      this.processTableLoading = true;
      this.api.dataset.processList({
        ...processSearchForm,
        page: processPagination.page,
        page_size: processPagination.pageSize,
      }).then(res => {
        this.processTableLoading = false;
        processPagination.total = Number(res.count);
        this.processList = res.list || [];
      });
    },
    processCurrentChange(page) {
      const { processPagination } = this;
      processPagination.page = page;
      this.getProcessTableData();
    },
    processSizeChange(size) {
      const { processPagination } = this;
      processPagination.pageSize = size;
      this.getProcessTableData();
    },
    createProcess() {
      const { processForm } = this;
      this.api.dataset.flowSave(processForm).then((res) => {
        this.$Message.success("创建成功");
        this.$router.push({
          path: "/flowchart",
          query: {
            flowChartName: res.info.name,
            flowChartId: res.info.id,
          }
        });
      });
    },
    editProcess(row) {
      this.$router.push({
        path: "/flowchart",
        query: {
          flowChartName: row.name,
          flowChartId: row.id,
          flowChartData: encodeURIComponent(row.process_data)
        }
      });
    },
    deleteProcess(row) {
      this.api.dataset.delProcess({
        id: row.id
      }).then(() => {
        this.$Message.success("删除成功");
        this.getProcessTableData();
      });
    },



    // 第三方系统相关函数
    thirdPartySystems() {
      this.tsLoading = true;
      this.api.dataset.thirdPartySystems().then(res => {
        this.tsPagination.total=Number(res.count);
        this.tsTableList = res.list || [];
        this.tsLoading = false;
      });
    },
    tsCurrentChange(page) {
      const { tsPagination } = this;
      tsPagination.page = page;
      this.thirdPartySystems();
    },
    tsSizeChange(size) {
      const { tsPagination } = this;
      tsPagination.pageSize = size;
      this.thirdPartySystems();
    },
    goExper(row) {
      this.$router.push({
        path:'/practical-experiment',
        query:{
          id:row.id
        }
      })

      // this.api.course.getLinkAuthUrl({
      //   experiment_id: row.id
      // }).then(res => {
      //   this.experLinkLoading = false;
      //   window.open(res.link, "_blank");
      // });
    },
    goExperiment(row){
      this.$router.push({
        path: "/flowchart",
        query: {
          flowChartName: row.name,
          flowChartId: row.id,
         // flowChartData: row.process_data ? encodeURIComponent(row.process_data) : '',
        }
      })
    },
    virtualExperiment(){
      let params = {
        page: this.virtualPagination.page,
        page_size: this.virtualPagination.pageSize,
      };
      this.virtualLoading = true;
      this.api.dataset.dockerListForUser(params).then(res => {
        this.virtualLoading = false;
        this.virtualTableData = res.list;
        this.virtualPagination.total=Number(res.count);
      }).catch((err)=>{
        this.virtualLoading = false;
      });
    },
    virtualCurrentChange(page) {
      this.virtualPagination.page = page;
      this.virtualExperiment();
    },
    virtualSizeChange(size) {
      this.virtualPagination.pageSize = size;
      this.virtualExperiment();
    },
    doingVirtualExercises(data){
      this.$router.push({
        path: "/virtual",
        query: {
          id: data.id
        }
      });
    },
    alilExperiment(){
      let params = {
        page: this.aliPagination.page,
        page_size: this.aliPagination.pageSize,
      };
      this.aliLoading = true;
      this.api.dataset.aliyunListForUser(params).then(res => {
        this.aliLoading = false;
        this.aliTableData = res.list;
        this.aliPagination.total=Number(res.count);
      }).catch((err)=>{
        this.aliLoading = false;
      });
    },
    aliCurrentChange(page) {
      this.aliPagination.page = page;
      this.alilExperiment();
    },
    aliSizeChange(size) {
      this.aliPagination.pageSize = size;
      this.alilExperiment();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            'ali_ak':this.ruleForm.aliAk,
            'ali_sk':this.ruleForm.aliSk,
          };
          this.api.dataset.aliyunSave(params).then(()=>{
            this.getUserInfo();
            if(this.enterExper){
              this.$router.push({
                path: "/aliyun",
                query: {
                  id: this.curAliData.id,
                }
              });
            }else{
              this.$Message.success("保存成功");
              this.aliModal=false;
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.enterExper=false;
      this.$refs[formName].resetFields();
    },
    doingaliExercises(data){
      this.curAliData = data;
      if(this.$store.state.user.userInfo.userAttribute.ali_ak && this.$store.state.user.userInfo.userAttribute.ali_sk){
        this.$router.push({
          path: "/aliyun",
          query: {
            id: data.id,
          }
        });
      }else{
        this.enterExper=true;
        this.aliModal = true;
      }
    },
    getUserInfo () {
      this.api.index.userDetail().then((res)=>{

        const userDetail = res.user;

        if (userDetail) {
          this.$store.state.user.userInfo = {
            ...this.$store.state.user.userInfo,
            ...userDetail
          };

        }
      })
    },
  },
  created() {
    this.activeExperTab = this.$route.params.tab || "";
    this.handleKeyword();
    this.getTableData();
    this.getProcessTableData();
    this.thirdPartySystems();
    this.virtualExperiment();
    this.alilExperiment();
  }
}
</script>

<style lang="scss" scoped>
.experList {
  padding: 20px;

  .experlist-main {
    background-color: white;
    padding: 20px;
    border-radius: 6px;

    .process-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .search-input {
        margin-bottom: 0;
      }
    }

    .search-input {
      width: 200px;
      margin-bottom: 20px;
    }

    .pagin {
      margin-top: 20px;
      text-align: end;
    }
  }
  .demo-ruleForm{
    margin-top: 20px;
  }
}
</style>
